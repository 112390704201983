import { forwardRef } from "react";
import {t} from "../fake-translation";

const ShapeItem = forwardRef(({ category, categoryShapes, onClick, isActive }, ref) => {
  const clickHandler = (shape) => {
    onClick(shape);
  };

  return (
    <div
      key={category}
      ref={ref}
      data-category={category}
      className="shape_selector--category-section"
    >
      <h2>{t(category)}</h2>
      <div className="shape_selector--list">
        {categoryShapes.map((shape) => (
          <div key={shape.id} className="shape-item" onClick={() => clickHandler(shape)}>
            {shape.name}
          </div>
        ))}
      </div>
    </div>
  );
});

export default ShapeItem;
