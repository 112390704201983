import React, { useState, useEffect, useCallback } from "react";
import {
    getArticlesAll,
    getArticlesData,
    getArticlesDataAll, getGalleryArticleId, getGalleryFullScreen, getGalleryIndex,
    getIsArticlesAllLoading,
    getIsArticlesLoading, getIsGalleryMode, getIsReadMoreState,
} from "../../store/selectors";
import { connect } from "react-redux";
import ArticleItem from "../UI/ArticleItem";
import Gallery from "../gallery";
import ArticleReadMore from "../articleReadMore";
import Popup from "../UI/Popup";
import { getArticleAllFromAPI } from "../../store/actions/articlesAll";
import { setAudioPlayingFunc } from "../../store/actions/audio";
import {
    setGalleryFullScreen,
    setGalleryIndex,
    setIsGalleryState,
    setIsReadMoreState
} from "../../store/actions/mapStateAction";
import {useSearchParams} from "react-router-dom";

let url = new URL(window.location.href);
const queryParams = new URLSearchParams(window.location.search);

const ArticlePopup = (props) => {
  const {
    articleData,
    articleLoading,
    getArticleAllFromAPI,
    articleAllLoading,
    articleAllData,
    stopPinAnimation,
    map,
    showReadMore,
    isGallery,
    galleryIndex,
    setIsReadMoreState,
    setGalleryIndex,
    galleryFullScreen,
    setGalleryFullScreen,
    setIsGalleryState,
    galleryArticleId
  } = props;
  const [showGallery, setShowGallery] = useState(false);
  const [isFromReadMore, setFromReadMore] = useState(false);
  const [showArticlePopup, setShowArticlePopup] = useState(false);
  const [clickedMediaImage, setClickedMediaImage] = useState(null);

  const toggleGallery = useCallback(
    (id, readMore = false) => {
      props.setAudioPlayingFunc(false);
      if (typeof id === "number") {
        setShowGallery(id);
      } else {
        setShowGallery(!showGallery);
      }
      setClickedMediaImage(id);
      setFromReadMore(!!readMore);
      setGalleryIndex(null)
      setGalleryFullScreen(false)
      setIsGalleryState(false)
    },
    [showGallery]
  );

  const toggleShowArticlePopup = useCallback(
    (id) => {
      props.setAudioPlayingFunc(false);
      setShowArticlePopup(!showGallery);
      getArticleAllFromAPI(id || galleryArticleId);
      setIsReadMoreState(!showReadMore)
      setGalleryIndex(null)
      // setClickedMediaImage(id)
    },
    [showGallery]
  );

  const toggleArticleMode = useCallback(
      (id) => {
          props.setAudioPlayingFunc(false);
          setShowArticlePopup(true);
          setShowGallery(false);
          getArticleAllFromAPI(id || galleryArticleId);
          setIsReadMoreState(!showReadMore)
          // setGalleryIndex(null)
      },
      []
  );

  useEffect(() => {
      if(showReadMore) {
          toggleArticleMode(articleData?.[0]?.id)
      } else {
          setShowArticlePopup(false)
      }
  },[showReadMore])


    useEffect(() => {
        if(showReadMore !== undefined && (galleryIndex !== null || isGallery)) {
            if(showReadMore && (galleryIndex >= 0 || isGallery)) {
                setTimeout(() => {
                    setShowGallery(true)
                }, 1000)
            } else if (!showReadMore && (galleryIndex >= 0 || isGallery)) {
                setShowGallery(true)
            }
        }
    },[galleryIndex, isGallery])

    const getGalleryProps = (id) =>{
        let findItem,findItemSort,galleryItems,title,feauturedImage,feauturedImageIdx;
        findItem = articleData?.find((x) => x.show === true)
        findItemSort = findItem?.articleData?.gallery?.sort((a, b) => a.order - b.order)
        galleryItems = findItemSort?.filter((x) => x.type !== 4)
        title = findItem?.articleData?.content?.[0].shortTitle
        if(typeof id === 'number'){
            feauturedImage = findItem?.articleData?.content?.[0].featuredImage
            feauturedImageIdx = galleryItems?.findIndex(el=>el.id===id)
        }else{
            feauturedImage = findItem?.articleData?.content?.[0].featuredImage
            feauturedImageIdx = galleryItems?.findIndex(el=>el.type===0)
        }
        return {
            findItem,
            findItemSort,
            galleryItems,
            title,
            feauturedImage,
            feauturedImageIdx
        }
    }


    const rendGallery = (id) =>{
        const galleryProps = getGalleryProps(showGallery)
        return (<Gallery
                    isGrid={isGallery}
                    mapState={map}
                    title={galleryProps.title}
                    gallery={showGallery}
                    toggleGallery={toggleGallery}
                    clickedMediaImage={clickedMediaImage}
                    feauturedImage={galleryProps.feauturedImage}
                    feauturedImageIdx={galleryIndex ?? galleryProps.feauturedImageIdx}
                    galleryItems={galleryProps.galleryItems}
                    fromArticle={isFromReadMore}
                    isFullScreen={galleryFullScreen}
                    setGalleryFullScreen={setGalleryFullScreen}
                    articleId={articleData?.[0]?.id}
                />)
    }
    // const changeURIParams = () => {
    //     let url = new URL(window.location.href);
    //     let params = new window.URLSearchParams(window.location.search);
    //     params.set("lessonID", id);
    //     url.search = params;
    //     url = url.toString();
    //     window.history.replaceState({ url: url }, null, url);
    //     window.location.reload();
    // };
    const closeArticlePopup = () =>{
        setShowArticlePopup (false)
        setIsReadMoreState(false)
        setGalleryIndex(null)
    }
  useEffect(() => {
    let flag = false;
    if (
      !flag &&
      articleData.length > 0 &&
      queryParams.get("bookmarkID") &&
      !showArticlePopup
    ) {
      flag = true;
      toggleShowArticlePopup(queryParams.get("bookmarkID"));
    }
  }, [articleLoading]);

    return (
    <>
      <Popup
          onClose={() => setShowArticlePopup(false)}
          isOpened={showArticlePopup}
          loading={!articleAllLoading && !!articleAllData?.data?.length}
        >
          <div className="article-read-more-content">
            <ArticleReadMore
              mapState={map}
              toggleShowArticlePopup={() => closeArticlePopup()}
              showArticlePopup={showArticlePopup}
              toggleGallery={(id) => toggleGallery(id, true)}
              galleryItems={getGalleryProps()?.galleryItems}
            />
            {showGallery && rendGallery(showGallery)}
          </div>
        </Popup>
      <div className="articles-grid">
        {articleData.length > 0 &&
          articleData.map((item) =>
              <ArticleItem
                  showReadMore={showReadMore}
                  key={item.id}
                  article={item}
                  toggleGallery={(id) => toggleGallery(id)}
                  toggleShowArticlePopup={(id) => toggleShowArticlePopup(id)}
                  stopPinAnimation={stopPinAnimation}
              />
          )}
      </div>
      {!!articleData.length &&
        !articleLoading &&
        showGallery &&
        rendGallery(showGallery)}
    </>
  );
};
const mapStateTopProps = (state) => ({
    articleData: getArticlesData(state),
    articleLoading: getIsArticlesLoading(state),
    articleAllLoading: getIsArticlesAllLoading(state),
    articleAllData:getArticlesAll(state),
    // showReadMore: getIsReadMoreState(state),
    isGallery: getIsGalleryMode(state),
    galleryIndex: getGalleryIndex(state),
    galleryFullScreen: getGalleryFullScreen(state),
    galleryArticleId: getGalleryArticleId(state)
})

const mapDispatchToProps = {
    getArticleAllFromAPI,
    setAudioPlayingFunc,
    setIsReadMoreState,
    setGalleryIndex,
    setGalleryFullScreen,
    setIsGalleryState
}

export default connect(mapStateTopProps, mapDispatchToProps)(ArticlePopup)
