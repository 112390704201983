import React, { memo, useCallback } from "react";
import { setPainterGeoJsonDataST } from "../../../../store/actions/mapStateAction";
import {
  setCommentToolboxState,
  setDrawerToolboxClickedState,
  setEraserClickedState,
  setLineDrawToolboxState,
  setRulerClickedState,
  setShaperClickState,
} from "../../../../store/actions/painterStart";
import { connect } from "react-redux";
import ShapeIcon from "../../../../assets/imgs/PaintBar/shape-icon.svg";
import { getShapeClickedState } from "../../../../store/selectors";
import {ShapeSelector, ShapeToolEditor} from "../index";
import {setModeIsActive} from "../../../../store/actions/shapeTool";
import {createPortal} from "react-dom";


const ShapeToolButton = ({
  getShapeClickedState,
  setShaperClickState,
  setStartPaintClickState,
  setLineDrawToolboxState,
  setCommentToolboxState,
  setEraserClickedState,
  setRulerClickedState,
                           setModeIsActive,
    map
}) => {
  const mapNode = document.getElementById("map");
  const onShapeHandle = useCallback(() => {
    // setStartPaintClickState(false);
    setLineDrawToolboxState(false);
    setCommentToolboxState(false);
    setEraserClickedState(false);
    setRulerClickedState(false);
    setShaperClickState(!getShapeClickedState);
    setModeIsActive(false);

  }, [getShapeClickedState]);

  return (
    <>
      {mapNode && createPortal(
          <ShapeToolEditor map={map}/>,
          mapNode
      )}

      <div
        id="shape_icon"
        className={`pain_items ${getShapeClickedState ? "button_active" : ""}`}
        onClick={onShapeHandle}
      >
        <img src={ShapeIcon} alt="" className="icon_img" />
      </div>
      {getShapeClickedState && (
        <div
          className="shape_tools_container"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <ShapeSelector onSelect={() => setShaperClickState(false)}/>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  getShapeClickedState: getShapeClickedState(state),
});
const mapDispatchToProps = {
  setCommentToolboxState: setCommentToolboxState,
  setEraserClickedState: setEraserClickedState,
  setDrawerToolboxClickedState: setDrawerToolboxClickedState,
  setPainterGeoJsonDataST: setPainterGeoJsonDataST,
  setLineDrawToolboxState: setLineDrawToolboxState,
  setRulerClickedState: setRulerClickedState,
  setShaperClickState: setShaperClickState,
  setModeIsActive: setModeIsActive,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(ShapeToolButton));
