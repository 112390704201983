import { COLORS_DATA, SIZE_DATA } from "../../utils/color_picker_data";
import {
  SET_COMMENT_TOOLBOX_CLICKED_STATE,
  SET_COLOR_PICKER_SHOW_STATE,
  SET_ERASER_CLICKED_STATE,
  SET_LINE_DRAW_TOOLBOX_STATE,
  SET_LINE_DRAW_TOOLBOX_WEIGHT,
  SET_LINE_DRAW_TOOLBOX_COLORS,
  SET_PAINTER_COLOR,
  SET_PAINTER_SIZE,
  SET_PAINTER_SIZE_DATA,
  SET_PAINTER_START,
  SET_RULER_CLICKED_STATE,
  SET_START_POINTER_CLICK_STATE,
  SET_LINE_DRAW_TOOLBOX_CURRENT_COLOR,
  SET_LINE_DRAW_TOOLBOX_COLOR_PICKER_STATE,
  SET_SHAPER_CLICKED_STATE,
} from "../constants";

const initialState = {
  data: false,
  painterColor: "#000000",
  painterColorData: COLORS_DATA,
  painterSize: 8,
  painterSizeData: SIZE_DATA,
  colorPickerShowState: false,
  startDrawerClickState: false,
  rulerClickedState: false,
  eraserClickedState: false,
  shapeClickedState: false,
  commentToolboxClickedState: false,
  lineDrawToolboxClickedState: false,
  lineDrawToolboxWeight: 3,
  lineDrawToolboxColors: [
    { id: 1, color: "#000000", status: 0 },
    { id: 2, color: "#157EFA", status: 0 },
    { id: 3, color: "#53D669", status: 1 },
    { id: 4, color: "#FED031", status: 0 },
    { id: 5, color: "#FC3142", status: 0 },
    { id: 6, status: 0 },
  ],
  lineDrawToolboxCurrentColor: COLORS_DATA[2].color,
  lineDrawToolboxColorPickerState: false,
};

export const painterStartReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LINE_DRAW_TOOLBOX_COLOR_PICKER_STATE:
      return {
        ...state,
        lineDrawToolboxColorPickerState: action.payload,
      };
    case SET_LINE_DRAW_TOOLBOX_WEIGHT:
      return {
        ...state,
        lineDrawToolboxWeight: action.payload,
      };
    case SET_LINE_DRAW_TOOLBOX_CURRENT_COLOR:
      return {
        ...state,
        lineDrawToolboxCurrentColor: action.payload,
      };
    case SET_LINE_DRAW_TOOLBOX_COLORS:
      return {
        ...state,
        lineDrawToolboxColors: action.payload,
      };
    case SET_LINE_DRAW_TOOLBOX_STATE:
      return {
        ...state,
        lineDrawToolboxClickedState: action.payload,
      };
    case SET_PAINTER_START:
      return {
        ...state,
        data: action.payload,
      };
    case SET_START_POINTER_CLICK_STATE:
      return {
        ...state,
        startDrawerClickState: action.payload,
      };
    case SET_PAINTER_COLOR:
      return {
        ...state,
        painterColor: action.payload,
      };
    case SET_PAINTER_SIZE:
      return {
        ...state,
        painterSize: action.payload,
      };
    case SET_PAINTER_SIZE_DATA:
      return {
        ...state,
        painterSizeData: action.payload,
      };
    case SET_COLOR_PICKER_SHOW_STATE:
      return {
        ...state,
        colorPickerShowState: action.payload,
      };
    case SET_RULER_CLICKED_STATE:
      return {
        ...state,
        rulerClickedState: action.payload,
      };
    case SET_ERASER_CLICKED_STATE:
      return {
        ...state,
        eraserClickedState: action.payload,
      };
    case SET_COMMENT_TOOLBOX_CLICKED_STATE:
      return {
        ...state,
        commentToolboxClickedState: action.payload,
      };
    case SET_SHAPER_CLICKED_STATE:
      return {
        ...state,
        shapeClickedState: action.payload,
      };
    default:
      return state;
  }
};
