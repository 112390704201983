import {useDispatch, useSelector} from "react-redux";
import {setActiveShape, setModeIsActive, setShapes} from "../../../store/actions/shapeTool";
import {useCallback} from "react";

const useShapes = () => {
    const dispatch = useDispatch()
    const shapes = useSelector(state => state.shapes.shapes)
    const activeShape = useSelector(state => state.shapes.activeShape)
    const modeIsActive = useSelector(state => state.shapes.modeIsActive)

    const onSetActiveShape = (item) => {
        dispatch(setActiveShape(item))
    }
    const onSetShapes = (data) => {
        dispatch(setShapes(data))
    }
    const onSetModeIsActive = (data) => {
        dispatch(setModeIsActive(data))
    }

    const pushShape = useCallback((item) => {
        onSetShapes([...shapes, item])
    },[])

    const removeShape = useCallback((id) => {
        onSetShapes(shapes.filter(item => item.id !== id))
    },[])

    const updateShape = useCallback((item) => {
        onSetShapes(shapes.map(shape => shape.id === item.id ? item : shape))
    },[])


    return {
        shapes,
        activeShape,
        modeIsActive,

        onSetActiveShape,
        onSetShapes,
        onSetModeIsActive,

        pushShape,
        removeShape,
        updateShape
    }
}

export default useShapes
