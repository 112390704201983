import React, {memo, useEffect, useState} from 'react';
import {connect} from "react-redux";

import {
    getLineDrawToolboxColorPickerState,
    getLineDrawToolboxColors,
    getLineDrawToolboxCurrentColor,
    getLineDrawToolboxWeight,
} from "../../../store/selectors";
import {
    setLineDrawToolboxColorPickerState,
    setLineDrawToolboxColors,
    setLineDrawToolboxCurrentColor,
    setLineDrawToolboxWeight,
} from "../../../store/actions/painterStart";

import {color_picker_data} from "../../../utils/color_picker_data";

import CloseIcon from '../../../assets/imgs/PaintBar/close-icon.svg';
import DeleteIcon from '../../../assets/imgs/PaintBar/delete-trash-icon.svg';

import "./style.css";


const LineDrawCreateEditPopup = (props) => {
    const {
        getLineDrawToolboxWeight,
        setLineDrawToolboxWeight,
        getLineDrawToolboxColors,
        getLineDrawToolboxCurrentColor,
        setLineDrawToolboxCurrentColor,
        getLineDrawToolboxColorPickerState,
        setLineDrawToolboxColorPickerState,
        handleClose,
        handleDelete,
        updateColorStatus,
    } = props;

    const [isOpenWeight, setIsOpenWeight] = useState(false);
    const [isOpenColors, setIsOpenColors] = useState(false);

    const toggleWeightsDropdown = () => {
        setIsOpenWeight(!isOpenWeight);
    };

    const toggleColorsDropdown = () => {
        if (getLineDrawToolboxColorPickerState && isOpenColors) {
            setLineDrawToolboxColorPickerState(false);
        }

        setIsOpenColors(!isOpenColors)
    }

    const handleOptionSelect = (option) => {
        setLineDrawToolboxWeight(option);
        setIsOpenWeight(false);
    };

    const colorSelectHandler = (e, color) => {
        e.preventDefault();
        e.stopPropagation();

        if (color.id === 6) {
            setLineDrawToolboxColorPickerState(!getLineDrawToolboxColorPickerState);
            return;
        }

        setLineDrawToolboxCurrentColor(color.color);
        updateColorStatus(color.id);
    }

    useEffect(() => {
        updateColorStatus(3)
    }, []);

    return (
            <>
            {isOpenColors &&
                <div className={'color-picker-popup'}>
                    {getLineDrawToolboxColors.map((color, i) => {
                        return (
                            <div
                                key={i}
                                className="painter_tools_color_data_item"
                                onClick={(e) => colorSelectHandler(e, color)}>
                                {i === getLineDrawToolboxColors.length - 1 ? (
                                    <div className={"color_circle"}>
                                        <svg
                                            style={{
                                                background: "conic-gradient( from 90deg, rgba(102, 70, 232, 1) 25%, rgba(86, 177, 68, 1) 49%, rgba(203, 197, 68, 1) 75%, rgba(232, 67, 61, 1) 100% )",
                                                borderRadius: "50%",
                                                height: 20,
                                                stroke: color.status ? "#2c476c" : '',
                                                strokeWidth: color.status ? "2" : "0",
                                            }}
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                        />
                                    </div>
                                ) : (
                                    <div className={"available-color"}>
                                        <svg
                                            style={{
                                                stroke: color.status ? "#2c476c" : color?.color,
                                                strokeWidth: color.status ? "2" : "0",
                                            }}
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox={color.status ? "0 0 20 20" : "0 0 24 24"}
                                            fill={color?.color}
                                        >
                                            <circle
                                                cx={color.status ? "10" : "12"}
                                                cy={color.status ? "10" : "12"}
                                                r={color.status ? "9" : "11.5"}
                                                stroke={color?.color}
                                            />
                                            <circle
                                                cx={color.status ? "10" : "12"}
                                                cy={color.status ? "10" : "12"}
                                                r={color.status ? "8" : "8.5"}
                                                fill={color?.color}
                                            />
                                        </svg>
                                    </div>
                                )}
                            </div>
                        )
                    })}
                </div>}
            {
                getLineDrawToolboxColorPickerState && (
                    <div
                        className="line_draw_tools_color_item_container"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                    >
                        {color_picker_data.map((color, i) => (
                            <div
                                key={i}
                                className="line_draw_tools_color_item"
                                style={{backgroundColor: color}}
                                onClick={() => {
                                    setLineDrawToolboxCurrentColor(color);
                                    updateColorStatus(6);
                                    setLineDrawToolboxColorPickerState(false);
                                }}
                            ></div>
                        ))}
                        <span></span>
                    </div>
                )}
            <div className='draw-lines-popup-container_color-picker' onClick={toggleColorsDropdown}>
                <span className={"current-picked-color"} onClick={toggleColorsDropdown}>
                    <svg
                        style={{stroke: '#2C476C'}}
                        strokeWidth="3"
                        xmlns="http://www.w3.org/2000/svg"
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                        fill={getLineDrawToolboxCurrentColor}
                    >
                        <circle
                            cx="14"
                            cy="14"
                            r="10.5"
                            stroke={getLineDrawToolboxCurrentColor}
                        />
                        <circle
                            cx="14"
                            cy="14"
                            r="9"
                            fill={getLineDrawToolboxCurrentColor}
                        />
                    </svg>
                </span>
            </div>
            <div className='draw-lines-popup-container_weight-picker' onClick={toggleWeightsDropdown}>
                <span className={'current-line-weight'}>{getLineDrawToolboxWeight}</span>
                {isOpenWeight &&
                    <div className="dropdown-content">
                        {[...Array(20).keys()].map((index) => {
                            const option = index + 1;
                            return (
                                <span
                                    key={option}
                                    className={option === getLineDrawToolboxWeight ? 'selected' : ''}
                                    onClick={() => handleOptionSelect(option)}
                                >
                                    {option}
                                </span>
                            );
                        })}
                    </div>
                }
            </div>
            <div className='draw-lines-popup-container_actions'>
                <span className={'delete-icon-wrapper'} onClick={handleDelete}>
                    <img className={'delete-icon'} src={DeleteIcon} alt="Delete"/>
                 </span>
                <span className={'close-icon-wrapper'} onClick={handleClose}>
                    <img className={'close-icon'} src={CloseIcon} alt="Close"/>
                 </span>
            </div>
            </>
    )
};

const mapStateToProps = (state) => ({
    getLineDrawToolboxWeight: getLineDrawToolboxWeight(state),
    getLineDrawToolboxColors: getLineDrawToolboxColors(state),
    getLineDrawToolboxCurrentColor: getLineDrawToolboxCurrentColor(state),
    getLineDrawToolboxColorPickerState: getLineDrawToolboxColorPickerState(state),
});

const mapDispatchToProps = {
    setLineDrawToolboxWeight: setLineDrawToolboxWeight,
    setLineDrawToolboxColors: setLineDrawToolboxColors,
    setLineDrawToolboxCurrentColor: setLineDrawToolboxCurrentColor,
    setLineDrawToolboxColorPickerState: setLineDrawToolboxColorPickerState,
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(LineDrawCreateEditPopup));