import {SET_ACTIVE_SHAPE, SET_MODE_IS_ACTIVE, SET_SHAPES} from "../actions/shapeTool";

const initialState = {
    activeShape: null,
    modeIsActive: false,
    shapes:[]
};

export const shapeToolReducer = (state = initialState, action) => { 
    switch (action.type) {
        case SET_ACTIVE_SHAPE:
            return {
                ...state,
                activeShape: action.payload,
            };
        case SET_MODE_IS_ACTIVE:
            return {
                ...state,
                modeIsActive: action.payload,
            };
        case SET_SHAPES:
            return {
                ...state,
                shapes: action.payload,
            };
        default:
            return state;
    }
};
