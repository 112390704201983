import React, {useState, useEffect, useRef, memo} from 'react';
import ReactDOM from "react-dom";
import {isMobile} from "react-device-detect";
import {connect, Provider} from "react-redux";
import mapboxgl from 'mapbox-gl';
import * as turf from "@turf/turf";
import {store} from "../../../store";

import LineDrawCreateEditPopup from "./LineDrawCreateEditPopup";

import {
    getLineDrawToolboxState,
    getLineDrawToolboxLinesState,
    getLineDrawToolboxLinesLoading,
    getLineDrawToolboxWeight,
    getLineDrawToolboxCurrentColor,
    getLineDrawToolboxColors,
} from "../../../store/selectors";

import {
    setEraserClickedState,
    setRulerClickedState,
    setDrawerToolboxClickedState,
    setLineDrawToolboxState,
    setLineDrawToolboxWeight,
    setLineDrawToolboxColors,
    setLineDrawToolboxCurrentColor,
    setCommentToolboxState,
} from "../../../store/actions/painterStart";

import {
    setLineDrawToolboxLinesLoading,
    setLineDrawToolboxLinesState
} from "../../../store/actions/mapStateAction";

import { changeCursorIcon } from "../../../shared/mockData";

import LineDraw from "../../../assets/imgs/PaintBar/line-draw-tool-icon.svg";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import "./style.css";
import "../../PaintBar/style.css";


const LineDrawToolbox = (props) => {

    const {
        setLineDrawToolboxState,
        getLineDrawToolboxLinesState,
        setLineDrawToolboxLinesState,
        getLineDrawToolboxState,
        setDrawerToolboxClickedState,
        setEraserClickedState,
        setRulerClickedState,
        getLineDrawToolboxWeight,
        getLineDrawToolboxCurrentColor,
        setLineDrawToolboxCurrentColor,
        setLineDrawToolboxWeight,
        getLineDrawToolboxColors,
        setLineDrawToolboxColors,
        getLineDrawToolboxLinesLoading,
        setLineDrawToolboxLinesLoading,
        setCommentToolboxState,
        draw,
        map
    } = props;

    const lines = useRef([]);
    const [clickEnabled, setClickEnabled] = useState(false);
    const popupRef = useRef(null);
    const hoverPopupRef = useRef(null);
    const currentFeatureID = useRef(null);
    const lastClickedCoordPath = useRef({parent: null, path: null});
    const mouseEnterAndOutTimeout = useRef(null);

    const updateColorStatus = (id) => {
        setLineDrawToolboxColors(
            getLineDrawToolboxColors.map((item) => {
                item.status = 0;
                if (item.id === id) {
                    item.status = 1;
                }
                return item;
            })
        );
    };

    const onVertexMouseEnterHandler = e => {
        const featureParentId = e.features[0].properties.parent;
        const parentFeature = lines.current.find(feature => feature.id === featureParentId);

        if (
            !e.features[0]?.properties?.parent ||
            e.features[0]?.properties?.coord_path === "0" ||
            !parentFeature ||
            isNaN(+(parentFeature?.properties?.distance[e.features[0]?.properties?.coord_path]))
        ) {
            return;
        }

        if (mouseEnterAndOutTimeout.current) clearTimeout(mouseEnterAndOutTimeout.current);

        hoverPopupRef.current.setDOMContent(createHoverPopUpCommentBox(parentFeature?.properties?.distance[e.features[0]?.properties?.coord_path]))
            .setLngLat(e.features[0].geometry.coordinates || e.lngLat)
            .addTo(map);

        if (isMobile) {
            hoverPopupRef.current.getElement().addEventListener('touchend', () => {
                onVertexMouseOutHandler();
            })
        }
    }

    const onVertexMouseOutHandler = () => {
        const hoverPopupContainer = document.querySelector('.hover-popUp-container-line-string');
        if (hoverPopupContainer) hoverPopupContainer.style.opacity = 0;
        mouseEnterAndOutTimeout.current = setTimeout(() => {
            hoverPopupRef.current.remove()
        }, 300)
    }

    const createHoverPopUpsWithDistances = (e) => {
        if (!e.features[0].properties.distance['1']) {
            return;
        }

        if (!isMobile) {
            map.off('mouseenter', "gl-draw-polygon-and-line-vertex-stroke-inactive.hot", onVertexMouseEnterHandler)
            map.off('mouseout', "gl-draw-polygon-and-line-vertex-stroke-inactive.hot", onVertexMouseOutHandler);
            map.off('mouseenter', "gl-draw-polygon-and-line-vertex-stroke-inactive.cold", onVertexMouseEnterHandler)
            map.off('mouseout', "gl-draw-polygon-and-line-vertex-stroke-inactive.cold", onVertexMouseOutHandler)

            map.on('mouseenter', "gl-draw-polygon-and-line-vertex-stroke-inactive.hot", onVertexMouseEnterHandler)
            map.on('mouseout', "gl-draw-polygon-and-line-vertex-stroke-inactive.hot", onVertexMouseOutHandler);
            map.on('mouseenter', "gl-draw-polygon-and-line-vertex-stroke-inactive.cold", onVertexMouseEnterHandler)
            map.on('mouseout', "gl-draw-polygon-and-line-vertex-stroke-inactive.cold", onVertexMouseOutHandler);
        }
    }

    const createDistancesPropertyForFeature = (e) => {
        const lineStringFeature = e.features[0];
        lineStringFeature.properties.distance = {};
        let lastDistance = 0;

        for (let i = 1; i < lineStringFeature.geometry.coordinates.length; i++) {
            const from = turf.point(lineStringFeature.geometry.coordinates[i]);
            const to = turf.point(lineStringFeature.geometry.coordinates[i - 1]);
            const options = {units: 'kilometers'};
            const distance = turf.distance(from, to, options);
            lastDistance += distance;

            lineStringFeature.properties.distance[i.toString()] = lastDistance;
        }
    }

    const updateLine = (e) => {
        setTimeout(() => { setLineDrawToolboxState(false) }); // ADDING TO QUEUE FOR STATE CHANGING QUEUE BALANCE

        switch (e.type) {
            case 'draw.create':
                const lineStringFeature = e.features[0];
                createDistancesPropertyForFeature(e);
                lineStringFeature.properties.portColor = '#53D669';
                lineStringFeature.properties.portWidth = 3;
                lines.current.push(lineStringFeature);
                const selectedColor = getLineDrawToolboxColors.find(color => color.color === lineStringFeature.properties.portColor);
                currentFeatureID.current = lineStringFeature.id;
                updateColorStatus(selectedColor);
                createHoverPopUpsWithDistances(e);
                if(isMobile) {
                    setTimeout(() => {
                        hoverPopupRef.current.remove();
                        popupRef.current.setLngLat(lineStringFeature.geometry.coordinates[lineStringFeature.geometry.coordinates.length - 1]).addTo(map);
                    })
                }
                break;
            case 'draw.update':
                const updatedFeature = e.features[0];
                createDistancesPropertyForFeature(e);
                lines.current = lines.current.map(feature => (feature.id === updatedFeature.id ? updatedFeature : feature))
                createHoverPopUpsWithDistances(e);
                break;
            case 'draw.delete':
                handleDelete();
                break;
            default:
                return;
        }
    };

    const handleClose = () => {
        popupRef.current.remove();
    }

    const handleDelete = () => {
        setTimeout(() => {
            const deletingFeature = draw.getSelected().features[0] || currentFeatureID.current;
            draw.delete(deletingFeature.id);
            lines.current = lines.current.filter(feature => feature.id !== deletingFeature.id);
            popupRef.current.remove();
        })
    }

    const changeFeatureProps = (id) => {
        if (!draw) return;
        if (id) {
            const thisFeature = lines.current.find(feature => feature.id === id);
            if (thisFeature && thisFeature.properties) {
                thisFeature.properties.portColor = getLineDrawToolboxCurrentColor;
                draw.setFeatureProperty(id, 'portColor', getLineDrawToolboxCurrentColor);
                setTimeout(() => {
                    thisFeature.properties.portWidth = getLineDrawToolboxWeight;
                })
                draw.setFeatureProperty(id, 'portWidth', getLineDrawToolboxWeight);
            }

            draw.add(draw.get(id));
        }
    }

    const onLineClickHandler = (e) => {
        const parentLineId = map.queryRenderedFeatures(e.point)[0].properties.id;
        const parentFeature = lines.current.find(feature => feature.id === parentLineId);
        if (!parentFeature) {
            return;
        }

        if (isMobile) {
            lastClickedCoordPath.current.parent = e.features[0].properties.parent;
            lastClickedCoordPath.current.coord_path = e.features[0].properties.coord_path || null;
        }

        currentFeatureID.current = parentLineId;

        const selectedColor = getLineDrawToolboxColors.find(color => color.color === parentFeature?.properties?.portColor);

        if(selectedColor && selectedColor.color !== parentFeature?.properties?.portColor) {
            updateColorStatus(selectedColor ? selectedColor.id : null);
            setLineDrawToolboxCurrentColor(parentFeature?.properties?.portColor);
            setLineDrawToolboxWeight(parentFeature?.properties?.portWidth);
        }
        popupRef.current.setLngLat(e.lngLat).addTo(map);
    }

    const onLineVertexClickHandler = (e) => {
        const markerLineProperties = map.queryRenderedFeatures(e.point)[0]?.properties;
        const parentFeature = lines.current.find(feature => feature.id === markerLineProperties.parent);
        if (!parentFeature) {
            return;
        }

        if (isMobile) {
            if (markerLineProperties.parent !== lastClickedCoordPath.current.parent || markerLineProperties.coord_path !== lastClickedCoordPath.current.coord_path) {
                onVertexMouseEnterHandler(e);
                lastClickedCoordPath.current.parent = e.features[0].properties.parent;
                lastClickedCoordPath.current.coord_path = e.features[0].properties.coord_path;
                return;
            }
            lastClickedCoordPath.current.parent = e.features[0].properties.parent;
            lastClickedCoordPath.current.coord_path = e.features[0].properties.coord_path;
        }

        currentFeatureID.current = markerLineProperties.parent;

        const selectedColor = getLineDrawToolboxColors.find(color => color.color === parentFeature?.properties?.portColor);

        if(selectedColor && selectedColor.color !== parentFeature?.properties?.portColor) {
            updateColorStatus(selectedColor ? selectedColor.id : null);
            setLineDrawToolboxCurrentColor(parentFeature?.properties?.portColor);
            setLineDrawToolboxWeight(parentFeature?.properties?.portWidth);
        }

        popupRef.current.setLngLat(e.lngLat).addTo(map);
    }

    const createHoverPopUpCommentBox = (text) => {
        const textToNum = +text;
        const km = textToNum.toFixed(1);
        const miles = (textToNum / 1.609).toFixed(2);
        const container = document.createElement('div');
        container.className = 'hover-popUp-container-line-string';

        const kilometersP = document.createElement('p');
        kilometersP.className = 'hover-popUp-text';
        kilometersP.textContent = km + ' կմ';

        const milesP = document.createElement('p');
        milesP.className = 'hover-popUp-text';
        milesP.textContent = miles + ' մղոն';

        container.appendChild(kilometersP);
        container.appendChild(milesP);

        return container;
    };

    const changeEditPopupPositionAsNeed = () => {
        const editPopupNode = popupRef.current.getElement();
        const nodeRect = editPopupNode?.getBoundingClientRect();
        if (!nodeRect) return;

        const popupWidth = 180; // popup width
        const popupHeight = 30; // popup height

        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;

        if (nodeRect.left + popupWidth > windowWidth) {
            editPopupNode.style.left = `-100px`;
        } else {
            editPopupNode.style.left = '100px';
        }

        if (windowHeight + nodeRect.top < windowHeight) {
            editPopupNode.style.top = `${popupHeight * 2}px`;
        } else {
            editPopupNode.style.top = '-10px';
        }
    }

    useEffect(() => {
        if (!getLineDrawToolboxLinesLoading) {
            lines.current = getLineDrawToolboxLinesState;
            setLineDrawToolboxLinesLoading(true);
            if (draw) {
                draw.set({
                    type: 'FeatureCollection',
                    features: lines.current,
                });
            }
        }
    }, [getLineDrawToolboxLinesLoading]);

    useEffect(() => {
        setLineDrawToolboxLinesState(lines.current);
    }, [lines.current]);

    useEffect(() => {
        if (clickEnabled) {
            setDrawerToolboxClickedState(false);
            setCommentToolboxState(false);
            setEraserClickedState(false);
            setRulerClickedState(false);
            changeCursorIcon()
        }
    }, [clickEnabled])

    useEffect(() => {
        setClickEnabled(getLineDrawToolboxState);
    }, [getLineDrawToolboxState]);

    useEffect(() => {
        map.on('draw.create', updateLine);
        map.on('draw.update', updateLine);
        map.on('draw.delete', updateLine);
        map.on('dragend', () => {
            changeEditPopupPositionAsNeed();
        });
        !isMobile && map.on('click', "gl-draw-polygon-and-line-vertex-stroke-inactive.hot", onLineVertexClickHandler);
        !isMobile && map.on('click', ['gl-draw-line-active.cold', 'gl-draw-line-active.hot', 'gl-draw-line-inactive.cold', 'gl-draw-line-inactive.hot'], onLineClickHandler);
        isMobile && map.on('touchend', ['gl-draw-polygon-and-line-vertex-stroke-inactive.hot', 'gl-draw-polygon-and-line-vertex-stroke-inactive.cold'], onLineVertexClickHandler);

        return () => {
            map.off('draw.create', updateLine);
            map.off('draw.update', updateLine);
            map.off('draw.delete', updateLine);

            !isMobile && map.off('click', "gl-draw-polygon-and-line-vertex-stroke-inactive.hot", onLineVertexClickHandler);
            !isMobile && map.off('click', ['gl-draw-line-active.cold', 'gl-draw-line-active.hot', 'gl-draw-line-inactive.cold', 'gl-draw-line-inactive.hot'], onLineClickHandler);
            isMobile && map.off('touchend', ['gl-draw-polygon-and-line-vertex-stroke-inactive.hot', 'gl-draw-polygon-and-line-vertex-stroke-inactive.cold'], onLineVertexClickHandler);
        }
    }, []);

    useEffect(() => {
        changeFeatureProps(currentFeatureID.current);
    }, [getLineDrawToolboxWeight, getLineDrawToolboxCurrentColor]);

    useEffect(() => {
        popupRef.current = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: true,
            maxWidth: 180,
            className: 'mapbox-popup-draw-line-container',
            anchor: 'bottom',
        });

        const container = document.createElement('div');
        container.className = 'draw-lines-popup-container';
        popupRef.current.setDOMContent(container);

        ReactDOM.render(
            <Provider store={store}>
                <LineDrawCreateEditPopup updateColorStatus={updateColorStatus}
                                         handleClose={handleClose}
                                         handleDelete={handleDelete}/>
            </Provider>,
            container);

        popupRef.current.on('open', (e) => {
            changeEditPopupPositionAsNeed();
            if (hoverPopupRef.current && hoverPopupRef.current.isOpen()) hoverPopupRef.current.remove();

            const currentFeatureProperties = lines.current.find(el => el.id === currentFeatureID.current)?.properties;

            if (currentFeatureProperties && currentFeatureProperties.portColor) {
                setLineDrawToolboxCurrentColor(currentFeatureProperties.portColor)
            } else {
                setLineDrawToolboxCurrentColor('#53D669');
            }
            if (currentFeatureProperties && currentFeatureProperties.portWidth) {
                setLineDrawToolboxWeight(currentFeatureProperties.portWidth)
            } else {
                setLineDrawToolboxWeight(3)
            }

            popupRef.current.setDOMContent(container);
        });

        popupRef.current.addTo(map);

        hoverPopupRef.current = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: true,
            className: 'mapbox-hover-popup-draw-line-container',
            dynamicPosition: true,
            anchor: 'bottom-right',
        });

        if(isMobile) {
            hoverPopupRef.current.on('open', (e) => {
                if (popupRef.current && popupRef.current.isOpen()) popupRef.current.remove();
            })
        }
    }, []);

    useEffect(() => {
        setTimeout(() => {
            if (getLineDrawToolboxState) {
                draw.changeMode('draw_line_string');
            } else {
                draw.changeMode('simple_select');
            }
        }, 100)
    }, [getLineDrawToolboxState]);

    return (
        <>
            <div
                id="lineDraw_icon"
                className={`pain_items ${getLineDrawToolboxState ? "button_active" : ""}`}
                onClick={() => {
                    setLineDrawToolboxState(!getLineDrawToolboxState)
                }}
            >
                <img src={LineDraw} alt="" className="icon_img"/>
            </div>
        </>
    );
};

const mapStateTopProps = (state) => ({
    getLineDrawToolboxState: getLineDrawToolboxState(state),
    getLineDrawToolboxLinesState: getLineDrawToolboxLinesState(state),
    getLineDrawToolboxLinesLoading: getLineDrawToolboxLinesLoading(state),
    getLineDrawToolboxWeight: getLineDrawToolboxWeight(state),
    getLineDrawToolboxColors: getLineDrawToolboxColors(state),
    getLineDrawToolboxCurrentColor: getLineDrawToolboxCurrentColor(state),
});

const mapDispatchToProps = {
    setDrawerToolboxClickedState: setDrawerToolboxClickedState,
    setEraserClickedState: setEraserClickedState,
    setRulerClickedState: setRulerClickedState,
    setCommentToolboxState: setCommentToolboxState,
    setLineDrawToolboxState: setLineDrawToolboxState,
    setLineDrawToolboxLinesState: setLineDrawToolboxLinesState,
    setLineDrawToolboxLinesLoading: setLineDrawToolboxLinesLoading,
    setLineDrawToolboxWeight: setLineDrawToolboxWeight,
    setLineDrawToolboxColors: setLineDrawToolboxColors,
    setLineDrawToolboxCurrentColor: setLineDrawToolboxCurrentColor,
};

export default connect(mapStateTopProps, mapDispatchToProps)(memo(LineDrawToolbox));
