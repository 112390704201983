import useImage from "use-image";
import {Image, Transformer} from "react-konva";
import {svgToURL} from "../utils";
import {useEffect, useRef} from "react";

const ShapeSvgImage = ({svg,onDragEnd,isSelected,onChange,onSelect,shapeProps, ...rest}) => {
    const url = svgToURL(svg);
    const [image] = useImage(url);
    const shapeRef = useRef();
    const trRef = useRef();

    useEffect(() => {
        if (isSelected) {
            // we need to attach transformer manually
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);


    const handleDragEnd = (e) => {
        const { x, y } = e.target.position();
        onDragEnd(e,{
            url: url,
            ...shapeProps,
            ...rest,
        });
    };


    return <>
        <Image {...rest}
               onClick={onSelect}
               onTap={onSelect}
               ref={shapeRef}
               onTransformEnd={(e) => {
                   console.log("event", e);
                   // transformer is changing scale of the node
            // and NOT its width or height
            // but in the store we have only width and height
            // to match the data better we will reset scale on transform end
            const node = shapeRef.current;
            const scaleX = node.scaleX();
            const scaleY = node.scaleY();

            // we will reset it back
            node.scaleX(1);
            node.scaleY(1);
            onChange(e,{
                ...shapeProps,
                x: node.x(),
                y: node.y(),
                // set minimal value
                width: Math.max(5, node.width() * scaleX),
                height: Math.max(node.height() * scaleY),
                rotation: node.rotation()
            });
        }} onDragEnd={handleDragEnd} image={image} rotation={shapeProps.rotation}/>
        {isSelected && (
            <Transformer
                ref={trRef}
                flipEnabled={true}
                boundBoxFunc={(oldBox, newBox) => {
                    // limit resize
                    if (Math.abs(newBox.width) < 5 || Math.abs(newBox.height) < 5) {
                        return oldBox;
                    }
                    return newBox;
                }}
            />
        )}
    </>
}

export default ShapeSvgImage
