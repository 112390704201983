import React, { memo, useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { connect } from "react-redux";

import LineDrawToolbox from "../toolbox/LineDrawToolbox";
import CommentToolbox from "../toolbox/CommentToolbox";
import RulerToolbox from "../toolbox/RulerToolbox";
import DrawerToolbox from "../toolbox/DrawerToolbox";
import MapScreenShotButton from "../UI/DynamicScreenshotCard/MapScreenShotButton/MapScreenShotButton";
import EraserToolbox from "../toolbox/EraserToolbox";
import EraserMockupComponent from "../toolbox/EraserToolbox/EraserMockupComponent";
import DeleteToolbox from "../toolbox/DeleteToolbox";
import UndoToolbox from "../toolbox/UndoRedoToolbox/UndoToolbox";
import RedoToolbox from "../toolbox/UndoRedoToolbox/RedoToolbox";

import {
  getCommentToolboxState,
  getDrawerToolboxClickedState,
  getEraserClickedState,
  getPainterStartData,
  getRulerClickedState,
  getScreenShootButtonStateST,
  getShapeClickedState,
} from "../../store/selectors";

import { MAP_TOOLBOX_KEYS } from "../mapToolbox";

import "./style.css";
import ShapeToolButton from "../toolbox/ShapeToolbox/shapeToolbar/ShapeToolButton";

const PaintBar = (props) => {
  const {
    map,
    painterStart,
    getCommentToolboxState,
    getDrawerToolboxClickedState,
    getEraserClickedState,
    getRulerClickedState,
    getScreenShootButtonStateST,
    getShapeClickedState,
    setShaperClickState,
    tools,
  } = props;

  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const hasEraser = tools?.[MAP_TOOLBOX_KEYS.ERASER];
  const hasRuler = tools?.[MAP_TOOLBOX_KEYS.RULER];
  const hasDrawer = tools?.[MAP_TOOLBOX_KEYS.DRAWER];
  const hasUndo = tools?.[MAP_TOOLBOX_KEYS.UNDO];
  const hasRedo = tools?.[MAP_TOOLBOX_KEYS.REDO];
  const hasDelete = tools?.[MAP_TOOLBOX_KEYS.DELETE];
  const hasLineDraw = tools?.[MAP_TOOLBOX_KEYS.LINE_DRAW];
  const hasCommentBox = tools?.[MAP_TOOLBOX_KEYS.COMMENT_BOX];

  const [isScreenShot, setIsScreenShot] = useState(query.get("screenShot"));

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    setIsScreenShot(() => query.get("screenShot"));

    if (query.get("loading") === "true") {
      updateSearchParams();
    }
  }, [searchParams]);

  const updateSearchParams = (e) => {
    const updatedSearchParams = new URLSearchParams(searchParams);
    updatedSearchParams.delete("loading");
    setSearchParams(updatedSearchParams);
  };

  useEffect(() => {
    setTimeout(() => {
      map.draw.changeMode(
        getDrawerToolboxClickedState ||
          getEraserClickedState ||
          getCommentToolboxState ||
          getRulerClickedState
          ? "static"
          : "simple_select"
      );
    }, 10);
  }, [
    getDrawerToolboxClickedState,
    getEraserClickedState,
    getCommentToolboxState,
  ]);

  return (
    <div
      className="paintBar_container"
      style={{ display: painterStart ? "flex" : "none" }}
    >
      <div id={"screen-shot-icon"} className="lastToolboxBtn">
        {painterStart && !isScreenShot && <MapScreenShotButton map={map} />}
        {painterStart && isScreenShot && (
          <MapScreenShotButton
            map={map}
            disable={getScreenShootButtonStateST}
          />
        )}
      </div>
      {hasDelete && <DeleteToolbox map={map} />}
      {hasUndo && <UndoToolbox map={map} />}
      {hasRedo && <RedoToolbox map={map} />}
      <ShapeToolButton map={map}/>
      {hasRuler && <RulerToolbox isScreenShot={isScreenShot} map={map} />}
      {hasEraser &&
        (hasDrawer ? (
          <EraserToolbox map={map} />
        ) : (
          <EraserMockupComponent map={map} />
        ))}
      {hasDrawer && <DrawerToolbox map={map} isScreenShot={isScreenShot} />}
      {hasCommentBox && <CommentToolbox map={map} />}
      {hasLineDraw && <LineDrawToolbox draw={map.draw} map={map} />}
    </div>
  );
};

const mapStateTopProps = (state) => ({
  painterStart: getPainterStartData(state),
  getDrawerToolboxClickedState: getDrawerToolboxClickedState(state),
  getRulerClickedState: getRulerClickedState(state),
  getEraserClickedState: getEraserClickedState(state),
  getScreenShootButtonStateST: getScreenShootButtonStateST(state),
  getCommentToolboxState: getCommentToolboxState(state),
  getShapeClickedState: getShapeClickedState(state),
});

const mapDispatchToProps = {};

export default connect(mapStateTopProps, mapDispatchToProps)(memo(PaintBar));
