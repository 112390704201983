import React, {memo, useCallback, useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import {isMobile} from "react-device-detect";
import { v4 as uuidv4 } from 'uuid';

import {
    getIsAllToolboxStatesLoaded,
    getPainterStartData,
    getRulerClickedState,
    getDrawerToolboxClickedState,
    getPainterGeoJsonDataST,
    getMapStyledId,
} from "../../../store/selectors";
import {
    setCommentToolboxState,
    setDrawerToolboxClickedState,
    setEraserClickedState,
    setLineDrawToolboxState,
    setRulerClickedState,
} from "../../../store/actions/painterStart";

import {
    dispatchUndoRedoData,
    setPainterGeoJsonDataST,
} from "../../../store/actions/mapStateAction";

import {changeCursorIcon, CURSOR_TYPE} from "../../../shared/mockData";
import {SIZE_DATA} from "../../../utils/color_picker_data";
import {
    color_picker_data,
    DRAWER_TOOLBOX_LAYER,
    ERASER_TOOLBOX_LAYER,
    MINIMAL_COORDINATES_LENGTH_COUNT
} from "./constants";

import BrashIcon from "../../../assets/imgs/PaintBar/brush.png";
import './style.css';


const DrawerToolbox = (props) => {
    const {
        getPainterStartData,
        getDrawerToolboxClickedState,
        setDrawerToolboxClickedState,
        getPainterGeoJsonDataST,
        setPainterGeoJsonDataST,
        getIsAllToolboxStatesLoaded,
        setCommentToolboxState,
        setLineDrawToolboxState,
        setRulerClickedState,
        setEraserClickedState,
        dispatchUndoRedoData,
        getMapStyledId,
        isScreenShot,
        map,
    } = props;

    const [drawerSizeData, setDrawerSizeData] = useState(SIZE_DATA);
    const drawerSize = useRef(8);
    const [getColorPickerShowState, setColorPickerShowState] = useState(false);
    const [colorsData, setColorsData] = useState([
        {id: 1, color: "#000000", status: 1},
        {id: 2, color: "#157EFA", status: 0},
        {id: 3, color: "#53D669", status: 0},
        {id: 4, color: "#FED031", status: 0},
        {id: 5, color: "#FC3142", status: 0},
        {id: 6, status: 0},
    ]);
    const drawerColor = useRef('#000'); // Default color
    const drawerGeoJsonRef = useRef(null);
    const currentDrawingLineRef = useRef(null);
    const isDrawing = useRef(false);

    const mouseDownDrawerHandlerRef = useRef(null);
    const mouseUpDrawerHandlerRef = useRef(null);
    const mouseMoveDrawerHandlerRef = useRef(null);
    // const lastMousePosition = useRef({x: null, y: null});

    const handleIconClick = () => {
        setDrawerToolboxClickedState(!getDrawerToolboxClickedState);
        setEraserClickedState(false);
        setLineDrawToolboxState(false);
        setCommentToolboxState(false);
        setRulerClickedState(false);
    };

    const getDrawingSource = () => {
        if(!map.getSource(DRAWER_TOOLBOX_LAYER))  {
            createSourceAndLayers();
        }
        return map.getSource(DRAWER_TOOLBOX_LAYER);
    };

    const updateColorStatus = (id) => {
        setColorsData(colorsData.map((item) => {
            item.status = 0;
            if (item.id === id) {
                item.status = 1;
            }
            return item;
        }))
    };

    const updateSizeColorIcon = (id) => {
        setDrawerSizeData(
            drawerSizeData.map((item) => {
                item.status = 0;
                if (item.id === id) {
                    item.status = 1;
                }

                return item;
            })
        );
    };

    const mouseMoveDrawerHandler = (e) => {
        // const currentPosition = { x: e.originalEvent.clientX, y: e.originalEvent.clientY };

        // if (
        //     Math.abs(currentPosition.x - lastMousePosition.current.x) < 2 ||
        //     Math.abs(currentPosition.y - lastMousePosition.current.y) < 2 ||
        //     !isDrawing.current
        // ) return;

        if(!isDrawing.current) return;

        const renderedDrawerFeatures = map.queryRenderedFeatures(e.point, {
            layers: [DRAWER_TOOLBOX_LAYER],
        });

        if (renderedDrawerFeatures.length > 0) {
            return;
        }

        const coords = e.lngLat.toArray();
        currentDrawingLineRef.current.geometry.coordinates.push(coords);
        if(currentDrawingLineRef.current.geometry.coordinates.length < 2) return;
        const source = getDrawingSource();
        source.setData(source._data);

        // lastMousePosition.current.x = currentPosition.x;
        // lastMousePosition.current.y = currentPosition.y;
    };

    const mouseDownDrawerHandler = (e) => {
        map.on('mouseout', documentOverHandler);
        map.on(`${isMobile ? 'mousemove' : 'mousemove'}`, mouseMoveDrawerHandlerRef.current)
        isDrawing.current = true;
        const id = uuidv4();
        currentDrawingLineRef.current = {
            id: id,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: []
            },
            properties: {
                id: id,
                color: drawerColor.current,
                width: drawerSize.current,
            }
        };

        const source = getDrawingSource();
        const data = source._data;
        data.features.push(currentDrawingLineRef.current);
        source.setData(data);
    };

    const mouseUpDrawerHandler = () => {
        map.off('mouseout', documentOverHandler);
        map.off(`${isMobile ? 'mousemove' : 'mousemove'}`, mouseMoveDrawerHandlerRef.current)
        isDrawing.current = false;
        currentDrawingLineRef.current = null;

        const source = getDrawingSource();
        const data = source._data;

        if (data.features.length && data.features[data.features.length - 1].geometry.coordinates.length <= MINIMAL_COORDINATES_LENGTH_COUNT) {
            data.features.pop();
            source.setData(data);
            setPainterGeoJsonDataST({...getDrawingSource()._data});
            return;
        }

        setPainterGeoJsonDataST({...getDrawingSource()._data});
        dispatchUndoRedoData({...JSON.parse(JSON.stringify(getDrawingSource()._data))});
    };

    const documentOverHandler = useCallback(() => {
        function removeListenerFromDocument(){
            document.removeEventListener('mouseup', offMouseOverFunctionOfMap)
        }
        function offMouseOverFunctionOfMap () {
            map.off('mouseover', removeListenerFromDocument)
            mouseUpDrawerHandlerRef.current()
        }
        document.addEventListener('mouseup', offMouseOverFunctionOfMap,{ once: true });
        map.once('mouseover', removeListenerFromDocument)
    }, []);

    useEffect(() => {
        if (getPainterStartData && getDrawerToolboxClickedState) {
            setTimeout(() => {
                changeCursorIcon(CURSOR_TYPE.DRAW)
                map["dragPan"].disable();
            }, 50);
            // touchstart
            // touchmove
            // touchend
            map.on(`${isMobile ? 'mousedown' : 'mousedown'}`, mouseDownDrawerHandlerRef.current);
            map.on(`${isMobile ? 'mouseup' : 'mouseup'}`, mouseUpDrawerHandlerRef.current);
        } else {
            changeCursorIcon();
            currentDrawingLineRef.current = null;
            map["dragPan"].enable();
            map.off(`${isMobile ? 'mousedown' : 'mousedown'}`, mouseDownDrawerHandlerRef.current);
            map.off(`${isMobile ? 'mouseup' : 'mouseup'}`, mouseUpDrawerHandlerRef.current);
            setDrawerToolboxClickedState(false);
        }
    }, [getPainterStartData, getDrawerToolboxClickedState]);

    const createSourceAndLayers = () => {
        const deleteAllLayers = () => {
            if (map.getLayer(DRAWER_TOOLBOX_LAYER)) {
                map.removeLayer(DRAWER_TOOLBOX_LAYER);
            }
            if (map.getLayer(ERASER_TOOLBOX_LAYER)) {
                map.removeLayer(ERASER_TOOLBOX_LAYER);
            }
            if (map.getSource(DRAWER_TOOLBOX_LAYER)) {
                map.removeSource(DRAWER_TOOLBOX_LAYER);
            }
            if (map.getSource(ERASER_TOOLBOX_LAYER)) {
                map.removeSource(ERASER_TOOLBOX_LAYER);
            }
        }

        deleteAllLayers();

        if(Object.keys(getPainterGeoJsonDataST).length > 0) {
            drawerGeoJsonRef.current = {...getPainterGeoJsonDataST};
        }

        if (!map.getSource(DRAWER_TOOLBOX_LAYER)) {
            map.addSource(DRAWER_TOOLBOX_LAYER, {
                'type': 'geojson',
                'data': drawerGeoJsonRef.current
            });
        }

        if (!map.getLayer(DRAWER_TOOLBOX_LAYER)) {
            map.addLayer({
                id: DRAWER_TOOLBOX_LAYER,
                type: 'line',
                source: DRAWER_TOOLBOX_LAYER,
                layout: {
                    'line-cap': 'round',
                    'line-join': 'round'
                },
                paint: {
                    'line-color': ['get', 'color'],
                    'line-width': ['get', 'width']
                }
            });
        }

        if (!map.getSource(ERASER_TOOLBOX_LAYER)) {
            map.addSource(ERASER_TOOLBOX_LAYER, {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: []
                }
            });
        }

        if (!map.getLayer(ERASER_TOOLBOX_LAYER)) {
            map.addLayer({
                id: ERASER_TOOLBOX_LAYER,
                type: 'fill',
                source: ERASER_TOOLBOX_LAYER,
                paint: {
                    'fill-color': '#0000ff',
                    'fill-opacity': 0.5
                }
            });
        }
    };

    useEffect(() => {
        mouseDownDrawerHandlerRef.current = mouseDownDrawerHandler;
        mouseUpDrawerHandlerRef.current = mouseUpDrawerHandler;
        mouseMoveDrawerHandlerRef.current = mouseMoveDrawerHandler;
        drawerGeoJsonRef.current = {
            type: 'FeatureCollection',
            features: []
        };

        createSourceAndLayers();
    }, []);

    useEffect(() => {
        setTimeout(() => {
            createSourceAndLayers();
            if (Object.keys(getPainterGeoJsonDataST).length > 0) {
                drawerGeoJsonRef.current = {...getPainterGeoJsonDataST};
            }
            getDrawingSource().setData(drawerGeoJsonRef.current);
        }, 2000)
    }, [getMapStyledId])

    useEffect(() => {
        if(!isScreenShot) return;
        if(!getIsAllToolboxStatesLoaded) return;
        if (Object.keys(getPainterGeoJsonDataST).length > 0) {
            drawerGeoJsonRef.current = {...getPainterGeoJsonDataST};
            getDrawingSource().setData(drawerGeoJsonRef.current);
        } else {
            getDrawingSource().setData(drawerGeoJsonRef.current);
        }
    }, [isScreenShot, getIsAllToolboxStatesLoaded]);

    return (
        <div style={{display: 'inline-block'}}>
            <div
                onClick={handleIconClick}
                className={`pain_items ${getDrawerToolboxClickedState ? "button_active" : ""}`}
            >
                <img src={BrashIcon} alt="" className="icon_img"/>
            </div>
            {getDrawerToolboxClickedState && (
                <div className="painter_tools_container">
                        <div className="painter_tools_size">
                            {drawerSizeData.map((item) => (
                                <div
                                    key={item.id}
                                    style={{
                                        backgroundColor: item?.status ? "#2c476c" : "",
                                    }}
                                    className="painter_tools_size_icon_block"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        updateSizeColorIcon(item?.id);
                                        drawerSize.current = parseInt(item.value);
                                    }}
                                >
                                    <div
                                        className="painter_tools_size_icon"
                                        style={{
                                            backgroundImage: `url(${
                                                item.status ? item?.active : item?.passive
                                            })`,
                                        }}
                                    ></div>
                                </div>
                            ))}
                        </div>
                        <div
                            style={{
                                width: "100%",
                                height: "1px",
                                background: "#BFBFC1",
                            }}
                        ></div>
                        <div className="painter_tools_color">
                            {colorsData.map((item, i) => (
                                <div
                                    key={i}
                                    className="painter_tools_color_data_item"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        updateColorStatus(item.id);
                                        if (!item?.img) {
                                            drawerColor.current = item?.color;
                                        }
                                        setColorPickerShowState(false);
                                        if (i === 5) {
                                            setColorPickerShowState(!getColorPickerShowState);
                                        }
                                    }}
                                >
                                    {i === 5 ? (
                                        <div className={"color_circle"}>
                                            <svg
                                                style={{
                                                    background:
                                                        "conic-gradient( from 90deg, rgba(102, 70, 232, 1) 25%, rgba(86, 177, 68, 1) 49%, rgba(203, 197, 68, 1) 75%, rgba(232, 67, 61, 1) 100% )",
                                                    borderRadius: "50%",
                                                    stroke: item.status ? "#fff" : "#0000",
                                                }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="#0000"
                                            >
                                                <circle cx="12" cy="12" r="11.5" stroke="#0000"/>
                                                <circle cx="12" cy="12" r="9.5" fill="#0000"/>
                                            </svg>
                                        </div>
                                    ) : (
                                        <div className={"color_circle"}>
                                            <svg
                                                style={{
                                                    stroke: item.status ? "#fff" : item?.color,
                                                }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill={item?.color}
                                            >
                                                <circle
                                                    cx="12"
                                                    cy="12"
                                                    r="11.5"
                                                    stroke={item?.color}
                                                />
                                                <circle
                                                    cx="12"
                                                    cy="12"
                                                    r="9.5"
                                                    fill={item?.color}
                                                />
                                            </svg>
                                        </div>
                                    )}
                                </div>
                            ))}
                            {getColorPickerShowState && (
                                <div
                                    className="painter_tools_color_item_container"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}
                                >
                                    {color_picker_data.map((color, i) => (
                                        <div
                                            key={i}
                                            className="painter_tools_color_item"
                                            style={{
                                                backgroundColor: color,
                                            }}
                                            onClick={() => {
                                                drawerColor.current = color;
                                                setColorPickerShowState(false);
                                            }}
                                        ></div>
                                    ))}
                                    <span/>
                                </div>
                            )}
                        </div>
                    </div>
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    getRulerClickedState: getRulerClickedState(state),
    getPainterStartData: getPainterStartData(state),
    getIsAllToolboxStatesLoaded: getIsAllToolboxStatesLoaded(state),
    getDrawerToolboxClickedState: getDrawerToolboxClickedState(state),
    getPainterGeoJsonDataST: getPainterGeoJsonDataST(state),
    getMapStyledId: getMapStyledId(state),
})
const mapDispatchToProps = {
    setCommentToolboxState: setCommentToolboxState,
    setLineDrawToolboxState: setLineDrawToolboxState,
    setDrawerToolboxClickedState: setDrawerToolboxClickedState,
    setRulerClickedState: setRulerClickedState,
    setEraserClickedState: setEraserClickedState,
    setPainterGeoJsonDataST: setPainterGeoJsonDataST,
    dispatchUndoRedoData: dispatchUndoRedoData,
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(DrawerToolbox));