
const TEST_SVG = '<svg xmlns="http://www.w3.org/2000/svg"\n' +
    '      width="526" height="233">\n' +
    '  <rect x="13" y="14" width="500" height="200" rx="50" ry="100"\n' +
    '      fill="none" stroke="blue" stroke-width="10" />\n' +
    '</svg>'

const TEST_2_SVG = '<svg height="140" width="500" xmlns="http://www.w3.org/2000/svg"> <ellipse rx="100" ry="50" cx="120" cy="80" style="fill:yellow;stroke:green;stroke-width:3" /> </svg>'

export const shapeCategories = [
    "main",
    "geometric",
    "ring",
    "animals",
    "nature",
    "equipment",
    "symbols",
    "religion",
    "shakuyan",
];

export const allShapes = {
    main: [
        {
            id: 1,
            name: "Square SVG",
            svg: TEST_SVG,
        },

        {
            id: 2,
            name: "Circle SVG",
            svg: TEST_2_SVG,
        },

        {
            id: 3,
            name: "Triangle",
            svg: "<svg></svg>",
        },

        {
            id: 4,
            name: "Rectangle",
            svg: "<svg></svg>",
        },

        {
            id: 5,
            name: "Ellipse",
            svg: "<svg></svg>",
        },

        {
            id: 6,
            name: "Star",
            svg: "<svg></svg>",
        },
    ],

    geometric: [
        {
            id: 7,
            name: "Pentagon",
            svg: TEST_SVG,
        },

        {
            id: 8,
            name: "Hexagon",
            svg: TEST_SVG,
        },

        {
            id: 9,
            name: "Octagon",
            svg: "<svg></svg>",
        },

        {
            id: 10,
            name: "Hexagon",
            svg: "<svg></svg>",
        },

        {
            id: 11,
            name: "Octagon",
            svg: "<svg></svg>",
        },

        {
            id: 12,
            name: "Hexagon",
            svg: "<svg></svg>",
        },

        {
            id: 13,
            name: "Octagon",
            svg: "<svg></svg>",
        },

        {
            id: 14,
            name: "Hexagon",
            svg: "<svg></svg>",
        },
    ],

    ring: [{
        id: 15,
        name: "Ring",
        svg: "<svg></svg>",
    }, {
        id: 16,
        name: "Ring",
        svg: "<svg></svg>",
    },{
        id: 17,
        name: "Ring",
        svg: "<svg></svg>",
    }],

    animals: [
        {
            id: 18,
            name: "Bear",
            type:"object",
            svg: "<svg></svg>",
        },

        {
            id: 19,
            name: "Flower",
            type: "object",
            svg: "<svg></svg>",
        },

        {
            id: 20,
            name: "Flower",
            type: "object",
            svg: "<svg></svg>",
        },

        {
            id: 21,
            name: "Flower",
            type: "object",
            svg: "<svg></svg>",
        },
    ],

    nature: [
        {
            id: 19,
            name: "Flower",
            svg: "<svg></svg>",
        },

        {
            id: 20,
            name: "Flower",
            svg: "<svg></svg>",
        },

        {
            id: 21,
            name: "Flower",
            svg: "<svg></svg>",
        },

        {
            id: 22,
            name: "Flower",
            svg: "<svg></svg>",
        },
    ],

    equipment: [
        {
            id: 22,
            name: "Flower",
            svg: "<svg></svg>",
        },

        {
            id: 23,
            name: "Flower",
            svg: "<svg></svg>",
        },

        {
            id: 24,
            name: "Flower",
            svg: "<svg></svg>",
        },
    ],

    symbols: [
        {
            id: 23,
            name: "Flower",
            svg: "<svg></svg>",
        },

        {
            id: 24,
            name: "Flower",
            svg: "<svg></svg>",
        },

        {
            id: 25,
            name: "Flower",
            svg: "<svg></svg>",
        },
    ],

    religion: [
        {
            id: 24,
            name: "Flower",
            svg: "<svg></svg>",
        },
        {
            id: 25,
            name: "Flower",
            svg: "<svg></svg>",
        },

        {
            id: 26,
            name: "Flower",
            svg: "<svg></svg>",
        },
    ],

    shakuyan: [
        {
            id: 25,
            name: "Flower",
            svg: "<svg></svg>",
        },
    ],
};
