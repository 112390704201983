import {Layer, Stage, Image, Star} from "react-konva";
import { clsx } from 'clsx';
import useShapes from "../useShapes";
import {svgToURL} from "../utils";
import ShapeSvgImage from "./ShapeSvgImage";
import mapboxgl from "mapbox-gl";
import {useEffect} from "react";

const markers = {}; // Object to store markers by shape ID


const ShapeToolEditor = ({map}) => {
  const {modeIsActive, onSetModeIsActive, activeShape, onSetShapes, onSetActiveShape, shapes} = useShapes();

    useEffect(() => {
        const shapeMarkers = document.querySelectorAll('.shape-marker');
        const mapContainer = document.getElementById("map");
        console.log(map);
        // map.on('click', (e) => {
        //
        //     const shapeId = e.target.getAttribute('data-shape-id');
        //     const shapeUuid = e.target.getAttribute('data-shape-uuid');
        //
        //     console.log("shapeId", shapeId, "shapeUuid", shapeUuid);
        //
        //     // When the map is clicked, get the geographic coordinate.
        //     const coordinate = map.unproject(e.point);
        //     console.log("coordinate", coordinate);
        // });

        mapContainer.addEventListener('click', (e) => {
            const shapeId = e.target.getAttribute('data-shape-id');
            const shapeUuid = e.target.getAttribute('data-shape-uuid');
            console.log("e", e);
            // console.log(" e.target",shapeUuid,shapeId, e.target);
            // onSetActiveShape(shapeId);
            console.log("shapeUuid", shapeUuid);
            if (shapeUuid) {
               const shape = shapes.find(shape => shape.uuid === shapeUuid);
               const point = map.project([shape.lng, shape.lat]);
               //calculate the center of the shape

               console.log("point", point);
               onSetShapes(shapes.map((item) => {
                   if (item.uuid === shapeUuid) {
                       const newItem = {
                           ...item,
                           x: point.x,
                           y: point.y
                       }
                       onSetActiveShape(newItem);
                       return newItem
                   }
                   return item
               }))

               // onSetModeIsActive(true);
               // alert(shapeUuid);
           }
        })

        return;
        console.log("shapeMarkers", shapeMarkers);
        // if (shapeMarkers) {
        //      console.log("shapeMarkers", shapeMarkers);
        //      shapeMarkers.forEach((marker) => {
        //          marker.addEventListener('click', (e) => {
        //              const shapeId = marker.getAttribute('data-shape-id');
        //              const shapeUuid = marker.getAttribute('data-shape-uuid');
        //              // onSetActiveShape(shapeId);
        //              alert(shapeId, shapeUuid);
        //          })
        //      });
        //  }
    }, []);

    const handleShapeChange = (e, item) => {
        const shape = e?.target;
        const { x, y, width, height } = shape ?  shape.getClientRect() : item;
        // Calculate the center of the shape
        const centerX = x + width / 2;
        const centerY = y + height / 2;

        // Convert Konva's (centerX, centerY) to Mapbox's (lng, lat)
        const mapboxContainer = map.getContainer();
        const mapboxRect = mapboxContainer.getBoundingClientRect();

        // Adjust x and y to be relative to the Mapbox container
        const relativeX = centerX + map.getContainer().offsetLeft - mapboxRect.left;
        const relativeY = centerY + map.getContainer().offsetTop - mapboxRect.top;

        // Convert pixel coordinates to geographic coordinates
        const lngLat = map.unproject([relativeX, relativeY]);

        onSetShapes(shapes.map(shape => {
            if (shape.uuid === item.uuid) {
                return {
                    ...shape,
                    // x,
                    // y,
                    // width,
                    // height,
                    // lng: lngLat.lng,
                    // lat: lngLat.lat
                };
            }
            return shape;
        }))

        updateMarkers(item, lngLat);

    };


    // Create a custom marker element with the SVG
    const createCustomMarker = ({svgUrl, width, height, id, uuid}) => {
        const img = document.createElement('img');
        img.className = 'shape-marker';
        img.dataset.shapeId = id;
        img.dataset.shapeUuid = uuid;
        img.src = svgUrl;
        img.style.width = width ? `${width}px` : '100px';  // Set appropriate size for your SVG
        img.style.height = height ? `${height}px` : '100px';
        img.style.transformOrigin = 'center'; // Ensure rotation is around the center
        img.style.objectFit = 'fill';

        return img;
    };


    const updateMarkers = (item, lngLat) => {
        const uuid = item.uuid;
        if (markers[uuid]) {
            if (lngLat) {
                markers[uuid].setLngLat(lngLat);
            }
            markers[uuid].getElement().style.width = `${item.width}px`;
            markers[uuid].getElement().style.height = `${item.height}px`;
            markers[uuid].setRotation(item.rotation);
            markers[uuid].setRotationAlignment('horizon')
            markers[uuid].getElement().style.transformOrigin = 'center';
        } else {
            // Create a new marker and store it in the markers object
            const newMarker = new mapboxgl.Marker({
                rotationAlignment:'horizon',
                rotation: item.rotation,
                element: createCustomMarker({
                    svgUrl: svgToURL(item.svg),
                    width: item.width,
                    height: item.height,
                    rotation: item.rotation,
                    id: item.id,
                    uuid: item.uuid
                }),
            })
                .setLngLat(lngLat)
                .addTo(map);
            markers[uuid] = newMarker;
        }
    }


    const checkDeselect = (e) => {
        // deselect when clicked on empty area
        const clickedOnEmpty = e.target === e.target.getStage();
        if (clickedOnEmpty) {
            onSetActiveShape(null);
            onSetModeIsActive(false);
        }
    };

    console.log("activeShape", activeShape);
    console.log("shapes", shapes);

    return (
   <div className={
     clsx("shape_tool_editor--container", {
       "shape_tool_editor--container--active": modeIsActive
     })
   } >
     <Stage id="shape-stage" width={window.innerWidth} height={window.innerHeight}  onMouseDown={checkDeselect}
            onTouchStart={checkDeselect} style={{
       width: "100%",
       height: "100%"
     }}>
       <Layer key={'shapes'}>
         {
           shapes.map((item,i) => {
               return <ShapeSvgImage  numPoints={5}
                                      // onDragMove={handleDragMove}
                             // fill="#89b717"
                             shadowColor="black"
                             shadowBlur={10}
                             shadowOpacity={0.6}
                                      shapeProps={item}
                             // opacity={0.8}
                             draggable width={item.width || 100}
                                      onDragEnd={handleShapeChange}
                              id={item.id}
                              height={item.height || 100}
                              x={item.x}
                              y={item.y}
                              isSelected={item.uuid === activeShape?.uuid}
                              onSelect={() => {
                                  onSetActiveShape(item);
                              }}
                              onChange={(e, newAttrs) => {
                                  const rects = shapes.slice();
                                  rects[i] = newAttrs;
                                  onSetShapes(rects);
                                  handleShapeChange(e,rects[i])
                              }}
                              rotation={item.rotation}
                              key={item.id}
                              svg={item.svg} />
           })
         }
       </Layer>
     </Stage>
   </div>
  );
};

export default ShapeToolEditor
