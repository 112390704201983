


const messages = {
    "main":"հիմնական",
    "geometric":"Երկրաչափական",
    "ring":"Օղակ",
    "animals":"Կենդանիներ",
    "nature":"Բնություն",
    "equipment":"Ունելիք",
    "symbols":"Սինվոլներ",
    "religion":"Կրոնություն",
    "shakuyan":"Շաքույան",
}


export const t = (key) => {
    return messages[key]
}
