export const SET_ACTIVE_SHAPE = "@SHAPE/SET_ACTIVE_SHAPE";
export const SET_SHAPES = "@SHAPE/SET_SHAPES";
export const SET_MODE_IS_ACTIVE = "@SHAPE/SET_MODE_IS_ACTIVE";


export const setActiveShape = data => ({
    type: SET_ACTIVE_SHAPE,
    payload: data,
});
export const setShapes = data => ({
    type: SET_SHAPES,
    payload: data,
});
export const setModeIsActive = data => ({
    type: SET_MODE_IS_ACTIVE,
    payload: data,
})
